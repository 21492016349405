// utils.js

/**
 * Converts a number to a hexadecimal string.
 * @param {number} num - The number to convert.
 * @returns {string} - The hexadecimal representation.
 */
export const toHex = (num) => {
    return `0x${num.toString(16)}`;
};

/**
 * Truncates an Ethereum address to a shorter format for display purposes.
 * @param {string} address - The Ethereum address to truncate.
 * @returns {string} - The truncated address.
 */
export const truncateAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 6)}...${address.slice(address.length - 4)}`;
};
