import React from "react";
import "./Cookies.css"; // Assuming similar styling as Terms.css

const CookiePolicy = () => {
	return (
		<div className="terms"> {/* Consider renaming the class to a more generic name like "policy" for shared styling */}
			<h4>Cookie Policy of Userbase</h4>
			<p className="terms__updated">Last updated: March 28, 2024</p>

			<h5>Introduction</h5>
			<p>
				At Userbase, we value your privacy and strive to be transparent about our use of cookies and similar technologies. This Cookie Policy explains how we use cookies and your choices regarding their use. By continuing to use Userbase, you consent to our Cookie Policy.
			</p>

			<h5>What are Cookies?</h5>
			<p>
				Cookies are small data files stored on your browser or device that help us improve your experience on Userbase. They enable us to remember your preferences, understand how you interact with our service, and improve our offerings based on that information.
			</p>

			<h5>How We Use Cookies</h5>
			<p>
				Userbase uses cookies for various purposes: to enhance your browsing experience, to understand user behavior, and to improve our services. We do not store sensitive personal information in cookies.
			</p>

			<h5>Your Choices</h5>
			<p>
				You have control over your cookie settings. Most browsers allow you to block or delete cookies from your device. However, if you choose to disable cookies, some features of Userbase may not operate as intended.
			</p>

			<h5>Third-Party Cookies</h5>
			<p>
				In addition to our own cookies, we may also use third-party cookies to collect statistics in aggregate form and analyze how users interact with Userbase. This helps us offer you a better experience.
			</p>

			<h5>Changes to This Policy</h5>
			<p>
				We may update this Cookie Policy from time to time. We encourage you to review this policy periodically for any changes. Your continued use of Userbase after the posting of changes constitutes your acknowledgment and consent of those changes.
			</p>

			<h5>Contact Us</h5>
			<p>
				If you have any questions about our use of cookies, please don't hesitate to contact us through our Telegram group. The link can be found on our main website.
			</p>
		</div>
	);
};

export default CookiePolicy;
