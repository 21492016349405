import React, { useEffect, useState } from "react";
import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Box,
	Text,
} from '@chakra-ui/react';
import Buttons from '../../components/Button/Button';
import CryptoJS from 'crypto-js';


import '@chakra-ui/react';
import { dp, closeIcon, searchIcon, hamburger, chatIcon, homeIcon, bellIcon } from "../../assets";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading, toggleSidebar } from "../../features/modalSlice";
import useFetch from "../../hooks/useFetch";
import SearchResults from "../SearchResults/SearchResults";
import { fetchUsersService } from "../../services/userServices";
import { fetchPostsService } from "../../services/postServices";
import { logout } from "../../features/userSlice";
import { setSearchQuery, setDoSearch } from '../../features/searchSlice';
import Notifications from '../Notifications/Notifications';
import "./appbar.css";
import CreatePost from "../CreatePost/CreatePost";
import { setAllPosts, setAllPostsType } from "../../features/postSlice";
import { EventEmitter } from "@solana/wallet-adapter-base";
import CreateNews from "../CreateNews/CreateNews";

const Appbar = () => {
	// global states
	const location = useLocation()
	const param = useParams();
	let userId = param?.id;

	const adminWallet = ['66a74dbfd0a2974d92aa0cde', '668241e3464d561c43c5182f']

	const pathname = location?.pathname
	const {
		user: { id, profileImage, isGuest },
		modal: { isSidebarVisible },
		search: { searchQuery: query, doSearch }
	} = useSelector(state => state);
	const {
		post: {
			allPosts: { posts, page, isLoading, type },
		},

	} = useSelector(state => state);
	const ENCRYPTION_KEY = 'info-solebook-admin';


	const resetInfinityScroll = new EventEmitter();


	// local states
	const [showNotifications, setShowNotifications] = useState(false);
	const [searchResult, setSearchResult] = useState({});
	const [createPostFlag, setCreatePostFlag] = useState('')
	const [account, setAccount] = useState('')

	const decrypt = (cipherText) => {
		const bytes = CryptoJS.AES.decrypt(cipherText, ENCRYPTION_KEY);
		return bytes.toString(CryptoJS.enc.Utf8);
	};
	const getWalletAddress = () => {
		const encryptedAddress = localStorage.getItem('walletAddress');
		if (encryptedAddress) {
			return decrypt(encryptedAddress);
		}
		return null;
	};

	useEffect(() => {
		const savedAddress = getWalletAddress();
		if (savedAddress) {
			setAccount(savedAddress);
		}
	}, []);


	const dispatch = useDispatch();
	const customFetch = useFetch();
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		if (doSearch) searchHandler();
	}, [doSearch, dispatch]);

	const searchHandler = async (e = null) => {
		dispatch(setDoSearch(false));
		e?.preventDefault();
		if (query.length > 0) {
			dispatch(setIsLoading(true));
			const { posts } = await customFetch(fetchPostsService, { query, type: 'all' });
			const { users } = await customFetch(fetchUsersService, { query });
			setSearchResult({ posts, users });
			dispatch(setIsLoading(false));
		}
	};

	const reset = () => {
		dispatch(setSearchQuery(""));
		dispatch(setDoSearch(false));
		setSearchResult({});
	};

	const gotoLogin = () => dispatch(logout());

	const handleSearchInput = (e) => {
		dispatch(setSearchQuery(e.target.value));
	}
	const buttons = ['all', 'followed'];
	const triggerPostType = async (postType) => {
		if (postType === type) return;
		console.log(postType, 'postTypepostType')
		dispatch(setAllPostsType(postType));
		resetInfinityScroll.emit('doReset');
		const data = await customFetch(fetchPostsService, { page: 1, type: postType, userId: id });
		dispatch(setAllPosts({ posts: data.posts, page: data.page }));
	}

	const OpenCreatePost = () => {
		onOpen()
		setCreatePostFlag('post')
	}
	const OpenCreateNews = () => {
		onOpen()
		setCreatePostFlag('news')
	}
	const closeFunction = () => {
		onClose()
		setCreatePostFlag('')
	}

	return (
		<header className={searchResult.posts || searchResult.users ? "appbar topZ" : "appbar"}>
			<div className="hamburger" onClick={() => dispatch(toggleSidebar(!isSidebarVisible))}>
				<img src={isSidebarVisible ? closeIcon : hamburger} alt="hamburger" />
			</div>
			<Link to="/">
				<img src={homeIcon} alt="home" className="home-icon" />
			</Link>
			<form onSubmit={searchHandler} className="searchform">
				<button type="submit" aria-label="search">
					<img src={searchIcon} alt="search" />
				</button>
				<input
					type="text"
					placeholder="Tap to search..."
					value={query}
					onChange={handleSearchInput}
				/>
				<button onClick={reset} type="button" aria-label="clear search">
					<img src={closeIcon} alt="close" className="close" />
				</button>
				{(searchResult.posts || searchResult.users) && (
					<SearchResults searchResult={searchResult} reset={reset} />
				)}
			</form>
			{!isGuest &&
				<div className="btn_create_post" onClick={OpenCreatePost}>
					<button >New Post</button>
				</div>}
			{!isGuest && pathname.includes("user") && adminWallet.includes(userId) &&
				<div className="btn_create_news" onClick={OpenCreateNews}>
					<button >Create News</button>
				</div>
			}
			<>
				{isOpen && <Box className="grey-overlay" onClick={closeFunction}></Box>}
				{isOpen && createPostFlag == 'post' && <Modal isOpen={isOpen} onClose={closeFunction} isCentered>
					<ModalOverlay />
					<ModalContent maxW="600px" minH="300px" maxH="auto" mx="auto" border={"1px solid #3c3e44"} borderRadius="20px" backgroundColor={"#191a35"} boxShadow="xl" p="4">
						<ModalHeader bg="blue.500" color="white" borderTopRadius="lg" marginTop={"10px"} marginLeft={"10px"} alignItems={"end"}>
							<ModalCloseButton color="white" />
						</ModalHeader>
						<ModalBody>
							<Box p="4" bg="gray.100" borderRadius="md">
								<Text fontSize="lg">
									{isGuest || <div className="createChat_main"> <CreatePost />{
										!isGuest && (
											<div className="btn-group create_chat_btn">
												{buttons?.map(title =>
													<Buttons title={title}
														isActive={type === title}
														backgroundColor="#0b0c27"
														key={title}
														onClick={() => triggerPostType(title)}
														disabled={isLoading}
													></Buttons>
												)}
											</div>
										)
									} </div>}
								</Text>
							</Box>
						</ModalBody>

					</ModalContent>
				</Modal>}
				{isOpen && createPostFlag == 'news' && <Modal isOpen={isOpen} onClose={closeFunction} isCentered>
					<ModalOverlay />
					<ModalContent maxW="600px" minH="300px" maxH="auto" mx="auto" border={"1px solid #3c3e44"} borderRadius="20px" backgroundColor={"#191a35"} boxShadow="xl" p="4">
						<ModalHeader bg="blue.500" color="white" borderTopRadius="lg" marginTop={"10px"} marginLeft={"10px"} alignItems={"end"}>
							<ModalCloseButton color="white" />
						</ModalHeader>
						<ModalBody>
							<Box p="4" bg="gray.100" borderRadius="md">
								<Text fontSize="lg">
									{isGuest || <div className="createChat_main"> <CreateNews /></div>}
								</Text>
							</Box>
						</ModalBody>

					</ModalContent>
				</Modal>}


			</>
			<nav className="appbar__profile">
				{isGuest ? (
					<button className="login-btn" onClick={gotoLogin}>
						Login
					</button>
				) : (
					<>
						<Link to={`/user/${id}`}>
							<img
								src={profileImage || dp}
								alt="profileImage"
								className="appbar__profile__dp"
								title="profile"
							/>
						</Link>
						<div
							onClick={() => setShowNotifications(!showNotifications)}
							className="bell"
						>
							<img src={bellIcon} alt="bell" />
							{showNotifications && (
								<div className="notifications">
									<Notifications />
								</div>
							)}
						</div>
					</>
				)}
			</nav>
		</header >
	);
};

export default Appbar;
