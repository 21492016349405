import React, { useState, useEffect } from 'react';
import './chat.css';
import fetchTrendingTokens from './fetchTrendingToken';
import GreenArrow from '../../assets/greenarrow.webp'
import RedArrow from '../../assets/redred.webp'
// import fetchTrendingTokens from './fetchTrendingTokens';

const TrendingTokens = () => {
    const [tokens, setTokens] = useState([]);
    const [hotPool, setHotPool] = useState('true');

    useEffect(() => {
        const getTrendingTokens = async () => {
            const data = await fetchTrendingTokens(hotPool);
            if (data) {
                setTokens(data.data);
            }
        };
        getTrendingTokens();
    }, [hotPool]);

    const hotPoolTokenFun = (value) => {
        setHotPool(value);
    }

    return (
        <div className="trending-tokens-container">
            <div className="tabs">
                <button style={{ fontSize: '10px' }} className={hotPool === 'true' ? 'active' : ''} onClick={() => hotPoolTokenFun('true')}>Daily Gainers</button>
                <button style={{ fontSize: '10px' }} className={hotPool === 'false' ? 'active' : ''} onClick={() => hotPoolTokenFun('false')}>Daily Losers</button>
            </div>
            <div className="tokens-table">
                {tokens?.slice(0, 10)?.map((token, index) => (
                    <div key={index} className="token-row">
                        <span style={{ fontSize: '10px' }} className="rank">#{index + 1}</span>
                        {/* <img src={token.mainToken.logo || 'default-logo.png'} alt={token.mainToken.name} className="token-icon" /> */}
                        {/* <span className="token-name">{token.mainToken.name}</span> */}
                        <span className="token-symbol">{token.mainToken.symbol}</span>
                        <span className="token-price" style={{ color: hotPool === 'true' ? '#00ff00' : '#f50505', fontSize: '8px' }}>${token.price.toFixed(11)}</span>
                        <span className="token-price" style={{ color: hotPool === 'true' ? '#00ff00' : '#f50505', fontSize: '8px', display: 'flex' }}><img src={hotPool == "true" ? GreenArrow : RedArrow} style={{ height: '10px', width: '10px', marginTop: '3px', marginRight: '3px' }} /> <span> {token.variation24h.toFixed(2)} %</span></span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TrendingTokens;
