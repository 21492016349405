import axios from 'axios';

const API_KEY = 'dRn1Q0OjL89viQoNG3zP38D8L2bdqK1DaFBJCEGd';

const fetchTrendingTokens = async (type) => {
    const apiUrl = type === 'true'
        ? 'https://public-api.dextools.io/trial/v2/ranking/solana/gainers?period=1m'
        : 'https://public-api.dextools.io/trial/v2/ranking/solana/losers?period=1m';

    try {
        const response = await axios.get(apiUrl, {
            headers: {
                'accept': 'application/json',
                'X-API-KEY': API_KEY
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching trending tokens:', error);
        return null;
    }
};

export default fetchTrendingTokens;
