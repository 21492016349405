import React, { useState, useEffect } from "react";
import Onboard from "@web3-onboard/core";
import { useDispatch } from "react-redux";
import { setIsLoading, showModal } from "../../features/modalSlice";
import { login } from "../../features/userSlice";
import useFetch from "../../hooks/useFetch";
import bs58 from 'bs58';

import { registerWithWallet } from "../../services/authServices";
import userbaselogo from "../../assets/userbaselogo.png"; // Ensure this path is correct
import Web3Provider from "../../providers/Web3Provider";
import { toHex, truncateAddress } from "../../providers/utils";
import injectedModule from "@web3-onboard/injected-wallets";
import walletConnectModule from "@web3-onboard/walletconnect";
import walletLinkModule from "@web3-onboard/walletlink";

const MAINNET_RPC_URL = `https://mainnet.infura.io/v3/2ca51d2dd17741b4a97029cb657db2d6`;
const ROPSTEN_RPC_URL = `https://ropsten.infura.io/v3/2ca51d2dd17741b4a97029cb657db2d6`;
const RINKEBY_RPC_URL = `https://rinkeby.infura.io/v3/2ca51d2dd17741b4a97029cb657db2d6`;

const injected = injectedModule();
const walletConnect = walletConnectModule({
  version: 2,
  projectId: '32bd930212095d42c6e2cd7be3d2d0e4'
});
const walletLink = walletLinkModule({
  appName: "My App",
  darkMode: false
});

const onboard = Onboard({
  wallets: [injected, walletLink, walletConnect],
  chains: [
    {
      id: "0x1",
      token: "ETH",
      namespace: "evm",
      label: "Ethereum Mainnet",
      rpcUrl: MAINNET_RPC_URL
    },
    {
      id: "0x3",
      token: "tROP",
      namespace: "evm",
      label: "Ethereum Ropsten Testnet",
      rpcUrl: ROPSTEN_RPC_URL
    },
    {
      id: "0x4",
      token: "rETH",
      namespace: "evm",
      label: "Ethereum Rinkeby Testnet",
      rpcUrl: RINKEBY_RPC_URL
    }
  ],
  appMetadata: {
    name: "My App",
    icon: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
    logo: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
    description: "My app using Onboard",
    recommendedInjectedWallets: [
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
      { name: "MetaMask", url: "https://metamask.io" }
    ]
  }
});

const Register = ({ setIsRegistering, isActive }) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const customFetch = useFetch();
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [error, setError] = useState("");
  const [network, setNetwork] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const connectWallet = async () => {
    try {
      setIsLoading(true);
      const wallets = await onboard.connectWallet();
      if (wallets.length > 0) {
        const { accounts, chains, provider } = wallets[0];
        setAccount(accounts[0].address);
        setChainId(chains[0].id);
        setProvider(provider);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const switchNetwork = async () => {
    try {
      await onboard.setChain({ chainId: toHex(network) });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleNetwork = (e) => {
    setNetwork(Number(e.target.value));
  };

  const disconnect = async () => {
    // try {
    //   const connectedWallets = onboard.state.get().wallets;
    //   if (connectedWallets.length > 0) {
    //     await onboard.disconnectWallet({ label: connectedWallets[0].label });
    //   }
      refreshState();
    // } catch (err) {
    //   setError(err.message);
    // }
  };

  const refreshState = () => {
    setAccount(null);
    setChainId(null);
    setProvider(null);
    setUserName('')
  };

  useEffect(() => {
    if (!isActive) return;
    if (provider && !account) {
      disconnect();
    }
  }, [provider, account, isActive]);
  // const loginWithWallet=()=>{
  //   if (!isActive || !provider || !account) return;
  //   (async () => {
  //     try {
  //       const message = `To avoid digital dognappers, sign below to authenticate with CryptoCorgis.`;
  //       const signature = await provider.request({
  //         method: 'personal_sign',
  //         params: [message, account]
  //       });

  //       const signatureBuffer = Buffer.from(signature.slice(2), 'hex');
  //       const signatureBase58 = bs58.encode(signatureBuffer);

  //       const payload = {
  //         name: userName,  // Assuming userName is defined somewhere in your component
  //         walletAddress: account,
  //         signature: signatureBase58
  //       };

  //       const data = await customFetch(registerWithWallet, payload);
  //       if (data) {
  //         dispatch(login(data));
  //       }
  //     } catch (error) {
  //       console.error("Registration failed:", error);
  //       dispatch(showModal({ msg: "Registration failed, please try again." }));
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   })();
  // }

  useEffect(() => {
    if (!isActive || !provider || !account) return;
    (async () => {
      try {
        const message = `To avoid digital dognappers, sign below to authenticate with CryptoCorgis.`;
        const signature = await provider.request({
          method: 'personal_sign',
          params: [message, account]
        });

        const signatureBuffer = Buffer.from(signature.slice(2), 'hex');
        const signatureBase58 = bs58.encode(signatureBuffer);

        const payload = {
          name: userName,  // Assuming userName is defined somewhere in your component
          walletAddress: account,
          signature: signatureBase58
        };

        const data = await customFetch(registerWithWallet, payload);
        if (data) {
          dispatch(login(data));
        }
      } catch (error) {
        // disconnect();

        console.error("Registration failed:", error);
        dispatch(showModal({ msg: "Registration failed, please try again." }));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isActive, provider, account, userName]);



  return (
    <div className="register" style={{ flexDirection: 'column', alignItems: 'center' }}>
      <img src={userbaselogo} alt="userbase Logo" style={{ marginBottom: '20px', maxWidth: '200px', height: 'auto' }} />
      <div>
        <label htmlFor="name">Username</label>
        <input
          type="text"
          id="name"
          placeholder="johnsol"
          value={userName}
          required
          onChange={(e) => setUserName(e.target.value)}
          style={{ width: '100%', padding: '8px', boxSizing: 'border-box', marginBottom: '20px' }}
        />
      </div>
      <Web3Provider
        connectWallet={connectWallet}
        switchNetwork={switchNetwork}
        handleNetwork={handleNetwork}
        disconnect={disconnect}
        refreshState={refreshState}
        isLoading={isLoading}
        account={account}
        error={error}
        truncateAddress={truncateAddress}
        // loginWithWallet={loginWithWallet}
        buttonText={'Register'}
      />

      <p>
        Already have an account? <span onClick={() => setIsRegistering(false)} style={{ cursor: 'pointer', color: '#007AFF', textDecoration: 'underline' }}>Login</span>
      </p>
    </div>
  );
};

export default Register;
