import {
    commentNewsService,
    createNewsService,
    deleteCommentService,
    deleteNewsService,
    editCommentService,
    fetchNewsService,
    likeNewsService,
    replyCommentService,
    reportNewsService,
    repostNewsService,
    updateNewsService,
} from '../services/newsService';
import { showModal } from './modalSlice';
import { logout, update } from './userSlice';
import trigger from '../utils/trigger';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    allNews: { news: [], page: 0, isLoading: false, type: 'all' },
    userNews: { posts: [], page: 0 },
    editingNews: {},
    singlePost: {},
    reportingNews: {},
    isLoading: false
};

const handleGuest = (isGuest, dispatch) => {
    if (isGuest) {
        dispatch(showModal({ msg: 'You must be logged in to do this action!!' }));
        return true;
    }
    return false;
};

export const setNews = createAsyncThunk('news/set', async (props, thunkAPI) => {
    const { customFetch } = props;
    const { rejectWithValue, dispatch } = thunkAPI;
    dispatch(newsSlice.actions.setLoading(true));
    const data = await customFetch(fetchNewsService, { type: 'all' });
    console.log(data, 'datadatadatadata')
    if (!data) return rejectWithValue();
    dispatch(newsSlice.actions.setAllNews(data));
    dispatch(newsSlice.actions.setLoading(false));
    return;
});

export const addNews = createAsyncThunk("news/add", async (props, thunkAPI) => {
    const { customFetch, formData } = props;
    const { fulfillWithValue, dispatch, rejectWithValue, getState } = thunkAPI;
    const {
        user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    dispatch(showModal({}));
    const data = await customFetch(createNewsService, formData);
    if (!data) return rejectWithValue();
    dispatch(showModal({ msg: "news created" }));
    return fulfillWithValue(data.post);
});

export const updateNews = createAsyncThunk("news/update", async (props, thunkAPI) => {
    const { customFetch, formData, id } = props;
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const {
        user: { isGuest },
        post: { singlePost },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    dispatch(showModal({}));
    const data = await customFetch(updateNewsService, { id, form: formData });
    if (!data) return rejectWithValue();
    dispatch(showModal({ msg: "News updated" }));
    if (singlePost._id === id) dispatch(newsSlice.actions.setSinglePost(data.post));
    dispatch(newsSlice.actions.updateNews(data.post));
});

export const reportPost = createAsyncThunk('post/report', async (props, thunkAPI) => {
    const { customFetch, reason, postId } = props;
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const {
        user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    dispatch(showModal({}));
    const data = await customFetch(reportNewsService, { postId, reason });
    if (!data) return rejectWithValue();
    dispatch(showModal({ msg: 'Report created' }));
});

export const likePost = createAsyncThunk('post/like', async (props, thunkAPI) => {
    const { customFetch, id, isLiked } = props;
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const {
        user: { isGuest, id: userId },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    customFetch(likeNewsService, { id, add: !isLiked });
    return { id, userId, add: !isLiked };
});

export const repostPost = createAsyncThunk("post/repost", async (props, thunkAPI) => {
    const { customFetch, postId: id, isReposted } = props;
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    dispatch(newsSlice.actions.setLoading(true));
    const {
        user: { isGuest, id: userId },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    const data = await customFetch(repostNewsService, { id, add: !isReposted });
    if (!data) return rejectWithValue();
    dispatch(showModal({ msg: !isReposted ? "Reposted" : "Repost removed" }));
    dispatch(newsSlice.actions.setLoading(false));
    return { id, userId, add: !isReposted };
})

export const commentPost = createAsyncThunk("post/comment", async (props, thunkAPI) => {
    const { customFetch, id, comment } = props;
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const {
        user: { isGuest },
        post: { singlePost },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    const data = await customFetch(commentNewsService, { id, comment });
    if (!data) return rejectWithValue();
    if (singlePost._id === id) dispatch(newsSlice.actions.setSinglePost(data.post));
    dispatch(newsSlice.actions.updateNews(data.post));
});

export const deleteNews = createAsyncThunk('news/delete', async (props, thunkAPI) => {
    const { customFetch, id, frontOnly } = props;
    const { dispatch, fulfillWithValue, getState, rejectWithValue } = thunkAPI;
    const {
        user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    if (!frontOnly) {
        await customFetch(deleteNewsService, { id });
    }
    dispatch(showModal({ msg: 'News Deleted' }));
    return fulfillWithValue(id);
});

export const deleteComment = createAsyncThunk('post/comment/delete', async (props, thunkAPI) => {
    const { customFetch, postId, commentId, replyId } = props;
    const { dispatch, fulfillWithValue, getState, rejectWithValue } = thunkAPI;
    const {
        user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    const data = await customFetch(deleteCommentService, { postId, commentId, replyId });
    dispatch(showModal({ msg: 'Comment Deleted' }));
    return fulfillWithValue(data);
});

export const editComment = createAsyncThunk('post/comment/edit', async (props, thunkAPI) => {
    const { customFetch, postId, commentId, comment, replyId } = props;
    const { dispatch, rejectWithValue, getState, fulfillWithValue } = thunkAPI;
    const {
        user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    const data = await customFetch(editCommentService, { postId, commentId, comment, replyId });
    dispatch(showModal({ msg: 'Comment Edited' }));
    return fulfillWithValue(data);
});

export const replyComment = createAsyncThunk('post/comment/reply', async (props, thunkAPI) => {
    const { customFetch, id, commentId, comment, replyTo } = props;
    const { dispatch, rejectWithValue, getState, fulfillWithValue } = thunkAPI;
    const {
        user: { isGuest },
    } = getState();
    if (handleGuest(isGuest, dispatch)) return rejectWithValue();
    const data = await customFetch(replyCommentService, { id, commentId, comment, replyTo });
    return fulfillWithValue(data);
});

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setAllNews: (state, action) => {
            const { posts, page } = action.payload;
            state.allNews = { ...state.allNews, news: posts, page };
        },
        setUserNews: (state, action) => {
            state.userNews = action.payload;
        },
        updateNews: (state, action) => {
            state.allNews.news = state.allNews.news.map((post) => {
                if (post._id === action.payload._id) return action.payload;
                return post;
            });
            state.userNews.posts = state.userNews.posts.map((post) => {
                if (post._id === action.payload._id) return action.payload;
                return post;
            });
        },
        setSinglePost: (state, action) => {
            state.singlePost = action.payload;
        },
        setEditingNews: (state, action) => {
            state.editingNews = action.payload;
        },
        setReportingNews: (state, action) => {
            state.reportingNews = action.payload;
        },
        setLoading: (state, action) => {
            state.allNews.isLoading = action.payload;
        },
        deleteAllUserNews: (state, action) => {
            state.allNews.news = state.allNews.news.filter(post => post.createdBy !== action.payload.id);
            state.userNews.posts = state.userNews.posts.filter(post => post.createdBy !== action.payload.id);
        },
        setAllNewsType: (state, action) => {
            state.allNews.type = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(addNews.fulfilled, (state, action) => {
            state.allNews.news.pop();
            state.userNews.posts.pop();
            state.allNews.news.unshift(action.payload);
            state.userNews.posts.unshift(action.payload);
        });
        builder.addCase(deleteNews.fulfilled, (state, action) => {
            state.allNews.news = state.allNews.news.filter((post) => post._id !== action.payload);
            state.userNews.posts = state.userNews.posts.filter((post) => post._id !== action.payload);
        });
        builder.addCase(likePost.fulfilled, (state, { payload }) => {
            trigger(state, { ...payload, type: 'likes' });
        });
        builder.addCase(repostPost.fulfilled, (state, { payload }) => {
            trigger(state, { ...payload, type: 'repostedBy' });
            const { userNews } = state;
            const { id, add } = payload;

            if (!add) {
                userNews.posts = userNews.posts.filter(post => post._id !== id);
            }
        });
        builder.addCase(update.type, (state, action) => {
            const { name, profileImage, id } = action.payload;
            state.allNews.news = state.allNews.news.map((post) => {
                if (post.createdBy === id) {
                    const updatedName = name || post.userDetails.name;
                    const updatedImage = profileImage || post.userDetails.image;
                    return {
                        ...post,
                        userDetails: { name: updatedName, image: updatedImage },
                    };
                }
                return post;
            });
            state.userNews.posts = state.userNews.posts.map((post) => {
                if (post.createdBy === id) {
                    const updatedName = name || post.userDetails.name;
                    const updatedImage = profileImage || post.userDetails.image;
                    return {
                        ...post,
                        userDetails: { name: updatedName, image: updatedImage },
                    };
                }
                return post;
            });
        });
        builder.addCase(deleteComment.fulfilled, (state, action) => {
            const { post } = action.payload;
            state.singlePost = post;
            state.allNews.news = state.allNews.news.map((_post) => (_post._id === post._id ? post : _post));
        });
        builder.addCase(editComment.fulfilled, (state, action) => {
            const { post } = action.payload;
            state.singlePost = post;
        });
        builder.addCase(replyComment.fulfilled, (state, action) => {
            const { post } = action.payload;
            state.singlePost = post;
        });
        builder.addCase(logout.type, (state, action) => {
            return initialState;
        });
        builder.addCase(setNews.pending, (state) => {
            state.allNews.isLoading = true;
        });
    },
});

export const {
    setUserNews,
    setAllNews,
    setEditingNews,
    setSinglePost,
    setReportingNews,
    deleteAllUserNews,
    setAllNewsType,
} = newsSlice.actions;

export default newsSlice.reducer;
