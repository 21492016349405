import extractParams from "../utils/extractParams";
import axiosConfig from "./axiosConfig";

const createNewsService = async (formData = {}) => {
    const headers = { "Content-Type": "multipart/form-data" };
    const { data } = await axiosConfig.post("/news", formData, { headers });
    return data;
};

const fetchNewsService = async (formData = {}) => {
    const params = extractParams(formData, "id", "query", "page", "userId", "type");
    const { data } = await axiosConfig.get("/news", { params });

    // Check if .posts exists and only then perform the mapping
    if (data.posts) {
        data.posts = data.posts.map(post => {
            // Resolves single image implementation
            if (post.image) {
                post.images = [post.image];
            }

            return post.hasOwnProperty('repostedPost') ? post.repostedPost : post;
        });
    }

    return data;
};

const likeNewsService = async (formData = {}) => {
    const params = extractParams(formData, "id", "add");
    const { data } = await axiosConfig.patch("/posts/like", params);
    return data;
};

const reportNewsService = async ({ postId, reason }) => {
    const { data } = await axiosConfig.post("/posts/report", { reason, postId });
    return data;
}

const repostNewsService = async ({ id, add }) => {
    const { data } = await axiosConfig.post("/posts/repost", { id, add });
    return data;
}

const commentNewsService = async (formData = {}) => {
    const params = extractParams(formData, "id", "comment");
    const { data } = await axiosConfig.post("/posts/comment", params);
    return data;
};

const deleteCommentService = async (formData = {}) => {
    const params = extractParams(formData, "postId", "commentId", "replyId");
    const { data } = await axiosConfig.delete(`/posts/comment`, { params });
    return data;
};

const editCommentService = async (formData = {}) => {
    const params = extractParams(formData, "postId", "commentId", "comment", "replyId");
    const { data } = await axiosConfig.patch(`/posts/comment`, params);
    return data;
};

const replyCommentService = async (formData = {}) => {
    const params = extractParams(formData, "id", "commentId", "comment", "replyTo");
    const { data } = await axiosConfig.post("/posts/comment", params);
    return data;
};

const deleteNewsService = async (formData = {}) => {
    const { id } = formData;
    const { data } = await axiosConfig.delete(`/news/${id}`);
    return data;
};

const updateNewsService = async (formData = {}) => {
    const { id, form } = formData;
    const { data } = await axiosConfig.patch(`/news/${id}`, form);
    return data;
};

export {
    fetchNewsService,
    createNewsService,
    likeNewsService,
    commentNewsService,
    deleteNewsService,
    updateNewsService,
    deleteCommentService,
    editCommentService,
    replyCommentService,
    reportNewsService,
    repostNewsService
};
