import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Onboard from "@web3-onboard/core";
import CryptoJS from 'crypto-js';
import { login } from "../../features/userSlice";
import { setIsLoading, showModal } from "../../features/modalSlice";
import { loginWithPasswordService, loginWithWalletService } from "../../services/authServices";
import bs58 from 'bs58';
import userbaselogo from "../../assets/userbaselogo.png";
import Button from '../Button/Button';
import './login.css';
import Web3Provider from "../../providers/Web3Provider";
import { toHex, truncateAddress } from "../../providers/utils";
import injectedModule from "@web3-onboard/injected-wallets";
import walletConnectModule from "@web3-onboard/walletconnect";
import walletLinkModule from "@web3-onboard/walletlink";

const MAINNET_RPC_URL = `https://mainnet.infura.io/v3/2ca51d2dd17741b4a97029cb657db2d6`;
const ROPSTEN_RPC_URL = `https://ropsten.infura.io/v3/2ca51d2dd17741b4a97029cb657db2d6`;
const RINKEBY_RPC_URL = `https://rinkeby.infura.io/v3/2ca51d2dd17741b4a97029cb657db2d6`;

const injected = injectedModule();
const walletConnect = walletConnectModule({
  version: 2,
  projectId: '32bd930212095d42c6e2cd7be3d2d0e4'
});
const walletLink = walletLinkModule({
  appName: "My App",
  darkMode: false
});

const onboard = Onboard({
  wallets: [injected, walletLink, walletConnect],
  chains: [
    {
      id: "0x1",
      token: "ETH",
      namespace: "evm",
      label: "Ethereum Mainnet",
      rpcUrl: MAINNET_RPC_URL
    },
    {
      id: "0x3",
      token: "tROP",
      namespace: "evm",
      label: "Ethereum Ropsten Testnet",
      rpcUrl: ROPSTEN_RPC_URL
    },
    {
      id: "0x4",
      token: "rETH",
      namespace: "evm",
      label: "Ethereum Rinkeby Testnet",
      rpcUrl: RINKEBY_RPC_URL
    }
  ],
  appMetadata: {
    name: "My App",
    icon: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
    logo: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
    description: "My app using Onboard",
    recommendedInjectedWallets: [
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
      { name: "MetaMask", url: "https://metamask.io" }
    ]
  }
});

const Login = ({ setIsRegistering, isActive }) => {
  const [isConnecting, setIsConnecting] = useState(0);
  const [loginStrategy, setLoginStrategy] = useState('wallet');
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);

  const [error, setError] = useState("");
  const [network, setNetwork] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const hasConnectedWallet = useRef(false);
  const ENCRYPTION_KEY = 'info-solebook-admin';

  const encrypt = (text) => {
    return CryptoJS.AES.encrypt(text, ENCRYPTION_KEY).toString();
  };



  const connectWallet = async () => {
    try {
      setIsLoading(true);
      const wallets = await onboard.connectWallet();
      if (wallets.length > 0) {
        const { accounts, chains, provider } = wallets[0];
        const encryptedAddress = encrypt(accounts[0].address);
        localStorage.setItem('walletAddress', encryptedAddress);
        setAccount(accounts[0].address);
        setChainId(chains[0].id);
        setProvider(provider);
        hasConnectedWallet.current = true;
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const switchNetwork = async () => {
    try {
      await onboard.setChain({ chainId: toHex(network) });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleNetwork = (e) => {
    setNetwork(Number(e.target.value));
  };

  const disconnect = async () => {
    // try {
    //   const connectedWallets = onboard.state.get().wallets;
    //   if (connectedWallets.length > 0) {
    //     await onboard.disconnectWallet({ label: connectedWallets[0].label });
    //   }
    refreshState();
    // } catch (err) {
    //   setError(err.message);
    // }
  };

  const refreshState = () => {
    setAccount(null);
    setChainId(null);
    setProvider(null);
    hasConnectedWallet.current = false; // Reset the connection flag
  };

  useEffect(() => {
    if (!isActive || !account || !hasConnectedWallet.current) return;
    setIsConnecting(1);
  }, [account, isActive]);

  // const loginWithWallet =()=>{
  //   if (!account || !provider || !chainId || isConnecting !== 1 || loginStrategy !== 'wallet') return;
  //   (async () => {
  //     setIsConnecting(2);
  //     try {
  //       const originalMessage = "Sign in to authenticate";
  //       const signature = await provider.request({
  //         method: 'personal_sign',
  //         params: [account, originalMessage]  // Swap params order for MetaMask compatibility
  //       });

  //       console.log('Signature:', signature);  

  //       let signatureBase58;
  //       try {
  //         const signatureBuffer = Buffer.from(signature.slice(2), 'hex');
  //         signatureBase58 = bs58.encode(signatureBuffer);
  //       } catch (err) {
  //         throw new Error('Invalid signature format');
  //       }

  //       const userData = await loginWithWalletService({
  //         walletAddress: account,
  //         signature: signatureBase58,
  //         message: originalMessage,
  //       });

  //       if (userData) {
  //         dispatch(login(userData));
  //       }
  //     } catch (error) {
  //       disconnect();
  //       if (error.response && [404, 422].includes(error.response.status)) {
  //         const errorMessage = error.response.data.message || 'Are you new here? Please make an account first.';
  //         console.log(errorMessage,'errorMessageerrorMessage')
  //         dispatch(showModal({ msg: errorMessage }));
  //       } else {
  //         console.error('Login with wallet failed:', error);
  //         dispatch(showModal({ msg: 'Login failed, please try again.' }));
  //       }
  //     } finally {
  //       setIsLoading(false);
  //       setIsConnecting(0);
  //     }
  //   })();
  // }

  useEffect(() => {
    if (!account || !provider || !chainId || isConnecting !== 1 || loginStrategy !== 'wallet') return;
    (async () => {
      setIsConnecting(2);
      try {
        const originalMessage = "Sign in to authenticate";
        const signature = await provider.request({
          method: 'personal_sign',
          params: [account, originalMessage]  // Swap params order for MetaMask compatibility
        });

        console.log('Signature:', signature);

        let signatureBase58;
        try {
          const signatureBuffer = Buffer.from(signature.slice(2), 'hex');
          signatureBase58 = bs58.encode(signatureBuffer);
        } catch (err) {
          throw new Error('Invalid signature format');
        }

        const userData = await loginWithWalletService({
          walletAddress: account,
          signature: signatureBase58,
          message: originalMessage,
        });

        if (userData) {
          dispatch(login(userData));
        }
      } catch (error) {
        // disconnect();
        if (error.response && [404, 422].includes(error.response.status)) {
          const errorMessage = error.response.data.message || 'Are you new here? Please make an account first.';
          dispatch(showModal({ msg: errorMessage }));
        } else {
          console.error('Login with wallet failed:', error);
          dispatch(showModal({ msg: 'Login failed, please try again.' }));
        }
      } finally {
        setIsLoading(false);
        setIsConnecting(0);
      }
    })();
  }, [account, isConnecting, provider, chainId, loginStrategy]);



  const handleStrategyClick = () => {
    setLoginStrategy(loginStrategy === 'wallet' ? 'password' : 'wallet');
  };

  const handleLoginButton = async () => {
    const userData = await loginWithPasswordService(username, password).catch(e => {
      dispatch(showModal(e.response.data));
    });

    if (userData) {
      dispatch(login(userData));
    }
  };

  return (
    <div className="login" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={userbaselogo} alt="userbase Logo" style={{ marginBottom: '20px', maxWidth: '200px', height: 'auto' }} />
      <div className="animated-container">
        {loginStrategy === 'wallet' && (
          <Web3Provider
            connectWallet={connectWallet}
            switchNetwork={switchNetwork}
            handleNetwork={handleNetwork}
            disconnect={disconnect}
            refreshState={refreshState}
            isLoading={isLoading}
            account={account}
            error={error}
            truncateAddress={truncateAddress}
            // loginWithWallet={loginWithWallet}
            buttonText={'Login'}
          />
        )}
        {loginStrategy === 'password' && (
          <div className="using-password">
            <label htmlFor="name">Username</label>
            <input
              type="text"
              id="name"
              placeholder="username"
              required
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="password"
              required
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              title="Login"
              backgroundColor="var(--primary-500)"
              onClick={handleLoginButton}
              isDisabled={!username || !password}
              isActive={true}
            />
          </div>
        )}
      </div>
      <div style={{ marginTop: "1rem" }}>
        use
        <span style={{ cursor: 'pointer', color: '#007AFF' }} onClick={handleStrategyClick}>
          {loginStrategy === 'wallet' ? ' password' : ' wallet'}
        </span>
      </div>
      <p>
        Don't have an account? <br />
        <span onClick={() => setIsRegistering(true)} style={{ cursor: 'pointer', color: '#007AFF' }}>Register</span>
      </p>
    </div>
  );
};

export default Login;
