import React from "react";
import "./Privacy.css"; // Ensure this CSS file sets text color to white

const Privacy = () => {
	return (
		<div className="terms"> {/* Consider renaming the class for shared use across policies */}
			<h4>Privacy Policy of Userbase</h4>
			<p className="terms__updated">Last updated: March 28, 2024</p>

			<h5>Introduction</h5>
			<p>
				Your privacy is important to us. This Privacy Policy explains how Userbase ("we", "us", or "our") collects, uses, shares, and protects user information obtained through the Userbase platform. By using our service, you agree to the collection and use of information in accordance with this policy.
			</p>

			<h5>Information Collection and Use</h5>
			<p>
				We collect information that you provide directly to us when creating an account, posting content, or communicating with other users. This may include your username, email address, and any other information you choose to provide. We use this information to operate, maintain, and provide the features of Userbase.
			</p>

			<h5>Log Data</h5>
			<p>
				When you access our service, our servers automatically record information sent by your browser ("Log Data"). This Log Data may include information such as your computer's IP address, browser type, browser version, our service pages that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
			</p>

			<h5>Cookies</h5>
			<p>
				We use cookies and similar technologies to collect information about how you interact with our service and to allow us to remember you. We use this information to improve and customize your browsing experience and for analytics and metrics about our visitors both on this service and other media.
			</p>

			<h5>Data Security</h5>
			<p>
				The security of your data is important to us. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
			</p>

			<h5>Data Sharing</h5>
			<p>
				We do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers.
			</p>

			<h5>Your Rights</h5>
			<p>
				You have the right to access, update, or delete the information we have on you. Whenever made possible, you can access, update, or request deletion of your Personal Information directly within your account settings section.
			</p>

			<h5>Changes to This Privacy Policy</h5>
			<p>
				We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
			</p>

			<h5>Contact Us</h5>
			<p>
				If you have any questions about this Privacy Policy, please contact us in our Telegram. Link on our main website.
			</p>
		</div>
	);
};

export default Privacy;
