import { combineReducers } from 'redux';
import userReducer from '../features/userSlice';
import modalReducer from '../features/modalSlice';
import postReducer from '../features/postSlice';
import messageReducer from '../features/messageSlice';
import socketReducer from '../features/socketSlice';
import usersReducer from '../features/usersSlice';
import searchReducer from '../features/searchSlice';
import adminReducer from '../features/adminSlice';
import newsReducer from '../features/newsSlice';
import notificationReducer from '../features/notificationSlice';

export default combineReducers({
	user: userReducer,
	modal: modalReducer,
	post: postReducer,
	message: messageReducer,
	socket: socketReducer,
	users: usersReducer,
	search: searchReducer,
	admin: adminReducer,
	news: newsReducer,
	notification: notificationReducer,
});
