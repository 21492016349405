import React from 'react';
import './button.css';

const Button = ({ title, onClick, isActive, backgroundColor, isDisabled = false }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick && onClick();
  }

  const buildStyles = () => {
    const activeBtn = {
      backgroundColor: 'white',
      color: 'black'
    };
    const defaultBackgroundColor = 'white';

    return {
      backgroundColor: backgroundColor ?? defaultBackgroundColor,
      ...(isActive && activeBtn)
    }
  }

  return (
    <button
      style={buildStyles()}
      className="app-button"
      onClick={handleClick}
      disabled={isDisabled}
    >{title}</button>
  )
};

export default Button;
