import React from 'react';
import './YellowBar.css'; // We'll add styles here

const YellowBar = ({ isVisible, setIsVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="yellow-bar">
      <p>JOIN $USER PRESALE <a href="/presale" target="_blank" rel="noopener noreferrer">HERE</a></p>
      <button onClick={() => setIsVisible(false)}>X</button>
    </div>
  );
};

export default YellowBar;
