import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { updateFCMTokenService } from './services/userServices';
import { showModal } from './features/modalSlice';

const vapidKey = process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY;
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const setupNotifications = async (dispatch) => {
    try {
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            const token = await getToken(messaging, { vapidKey });
            console.log(token, 'tokentoken-------')
            if (token) {
                updateFCMTokenService(token);
            }
        } else {
            console.log('Notification permission denied.');
        }
        onMessage(messaging, (payload) => {
            dispatch(showModal({ msg: payload.notification.title }));
        });
    } catch (error) {
        console.error('Error setting up notifications:', error);
    }
};
export { messaging, setupNotifications };
